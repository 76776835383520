import React from 'react';
import { IconProps } from '../Icons.interface';

export const EditOutlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1547 3.48798L12.512 5.84531M11.1547 2.48798C11.4673 2.17537 11.8912 1.99976 12.3333 1.99976C12.7754 1.99976 13.1994 2.17537 13.512 2.48798C13.8246 2.80058 14.0002 3.22456 14.0002 3.66664C14.0002 4.10873 13.8246 4.53271 13.512 4.84531L4.33333 14.024H2V11.6426L11.1547 2.48798Z"
      stroke="currentColor"
      strokeWidth="1.43434"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
