export class Coordinates {
  constructor(private long: number, private lat: number) {}

  get longitude() {
    return this.long;
  }

  get latitude() {
    return this.lat;
  }

  static convertToDmsFormat(dd: number, isLong = false): string {
    const dir = dd < 0 ? (isLong ? 'W' : 'S') : isLong ? 'E' : 'N';

    const absDd = Math.abs(dd);
    const deg = absDd | 0;
    const frac = absDd - deg;
    const min = (frac * 60) | 0;
    let sec = frac * 3600 - min * 60;

    sec = Math.round(sec * 100) / 100;
    return deg + '°' + min + "'" + sec + '"' + dir;
  }
}
