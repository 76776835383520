import React from 'react';
import { IconProps } from '../Icons.interface';

export const PlayIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 30C60 33.9397 59.224 37.8407 57.7164 41.4805C56.2087 45.1203 53.999 48.4274 51.2132 51.2132C48.4274 53.999 45.1203 56.2087 41.4805 57.7164C37.8407 59.224 33.9397 60 30 60C26.0603 60 22.1593 59.224 18.5195 57.7164C14.8797 56.2087 11.5726 53.999 8.7868 51.2132C6.00104 48.4274 3.79125 45.1203 2.28361 41.4805C0.775973 37.8407 0 33.9397 0 30C0 22.0435 3.1607 14.4129 8.7868 8.7868C14.4129 3.16071 22.0435 0 30 0C37.9565 0 45.5871 3.16071 51.2132 8.7868C56.8393 14.4129 60 22.0435 60 30ZM39.1735 27.2266L28.5168 20.12C28.0144 19.7847 27.4302 19.5923 26.8269 19.5633C26.2235 19.5343 25.6237 19.6698 25.0913 19.9553C24.559 20.2408 24.1143 20.6656 23.8047 21.1842C23.4951 21.7029 23.3322 22.2959 23.3335 22.9V37.11C23.3334 37.7134 23.4972 38.3056 23.8073 38.8233C24.1174 39.341 24.5622 39.7648 25.0943 40.0495C25.6264 40.3342 26.2258 40.4691 26.8286 40.4399C27.4313 40.4106 28.0148 40.2182 28.5168 39.8833L39.1735 32.7766C39.63 32.4722 40.0043 32.0598 40.2632 31.576C40.5221 31.0922 40.6576 30.552 40.6576 30.0033C40.6576 29.4546 40.5221 28.9144 40.2632 28.4306C40.0043 27.9468 39.63 27.531 39.1735 27.2266Z"
      fill="currentColor"
    />
  </svg>
);
